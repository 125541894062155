


jQuery(document).ready(function($) {

//	var base_path = '/comarp/public';
		var base_path = ''

	$(document).on('click', '.add_row_lav', function() {

		var $tr    = $(this).closest('.row_lav');
		var $clone = $(this).closest('tbody').find('.row_lav.new_row').clone();
		 
		$clone.removeClass('new_row').addClass('n_r'); 

		//azzero i valori
		$clone.find('input').val('');

		//dati riga
		var data_idrigalav = Number($tr.data('idrigalav'));
		var data_idlavorazione = Number($tr.data('idlavorazione'));

		var old_id = $tr.find(".btn_timer").attr('id'); 

		var pre_new_id = old_id.slice(0, old_id.indexOf("-")).replace("t_", "");
		var post_new_id = Number(old_id.split('-')[1]);
		post_new_id++;
		var new_id = pre_new_id + "-" + post_new_id;

		$clone.find(".badge").attr("id","t_"+new_id);
		$clone.find(".btn_timer").attr("id","btn_"+new_id);

		$tr.after($clone);

		$(this).remove();

		//cambio attr al tr appena aggiunto
		data_idrigalav++;
		var n_r = $tr.closest('tbody').find('.n_r');
		n_r.attr('data-idrigalav',data_idrigalav);
		n_r.attr('data-idlavorazione',data_idlavorazione);
		n_r.removeClass('n_r');
	});



	$(document).on('click', '.salva_lavorazione', function() {

		var params = {};

		var lavorazione_block = $(this).parents('.lavorazione_block');
		params.lavorazione = lavorazione_block.data('lavorazione');

		params.id_lavorazione = lavorazione_block.data('idlavorazione');


		var time_lav = lavorazione_block.find('.lav_time_count').text().replace('sec','').replace('min','').trim();
		var time_lav_sec = 0;

		if (time_lav == "00:00") {
			time_lav_sec = lavorazione_block.find('.lav_time_start').val();
		}
		else{

			time_lav_sec = time_to_sec(time_lav);
		}

		params.time_lav_sec = time_lav_sec;
		params.tot_scarti_lav = Number(lavorazione_block.find('input[name="tot_scarti_lav"]').val());
		params.tot_pezzi_lav = Number(lavorazione_block.find('input[name="tot_pezzi_lav"]').val());

		//se ho delle lavorazioni
		if(lavorazione_block.find('.tabellaRighe .row_lav').length > 0 ) params.lavorazioni = [];

		lavorazione_block.find('.tabellaRighe .row_lav').each(function(index, lav) {

			var lavorazione = {};

			lavorazione.descrizione =  $(lav).find('input[name="descrizione"]').val();

			lavorazione.macchinario =  Number($(lav).find('select[name="macchinario"]').val());

			var t_mac =  $(lav).find('input[name="time_lav"]').val();
			if(t_mac == ""){
				lavorazione.time =  time_to_sec( $(lav).find('.badge').text() );
			}
			else{
				lavorazione.time =  time_to_sec( $(lav).find('input[name="time_lav"]').val() );
			}

			lavorazione.n_pezzi =  Number($(lav).find('input[name="n_pezzi"]').val());

			lavorazione.n_scarti =  Number($(lav).find('input[name="n_scarti"]').val());

			params.lavorazioni.push(
			    lavorazione
			);
		});

		$.ajax({
			type: "POST",
			url: document.location.origin + base_path + '/area_produzione/salvaLavorazione',
			data: { 
				params: params
			},
			success: function(result){
				lavorazione_block.addClass('locked');
				if(result == 'OK'){
					swal("Salvato", "tempo lavorazione salvato", "success");
				}
				else{
					swal("Errore", "tempo lavorazione NON salvato", "error");
				}
			},
			error: function(){
				swal("Errore", "tempo lavorazione NON salvato", "error");
			}
		});

	});

	$(document).on('click', '.termina_lavorazione', function() {
		
			var params = {};
		
			var lavorazione_block = $(this).parents('.lavorazione_block');
			params.lavorazione = lavorazione_block.data('lavorazione');
		
			params.id_lavorazione = lavorazione_block.data('idlavorazione');
		
		
			var time_lav = lavorazione_block.find('.lav_time_count').text().replace('sec','').replace('min','').trim();
			var time_lav_sec = 0;
		
			if (time_lav == "00:00") {
				time_lav_sec = lavorazione_block.find('.lav_time_start').val();
			}
			else{
		
				time_lav_sec = time_to_sec(time_lav);
			}
		
			params.time_lav_sec = time_lav_sec;
			params.tot_scarti_lav = Number(lavorazione_block.find('input[name="tot_scarti_lav"]').val());
			params.tot_pezzi_lav = Number(lavorazione_block.find('input[name="tot_pezzi_lav"]').val());
		
				//se ho delle lavorazioni
				if(lavorazione_block.find('.tabellaRighe .row_lav').length > 0 ) params.lavorazioni = [];
		
				lavorazione_block.find('.tabellaRighe .row_lav').each(function(index, lav) {
		
					var lavorazione = {};
		
					lavorazione.descrizione =  $(lav).find('input[name="descrizione"]').val();
		
					lavorazione.macchinario =  Number($(lav).find('select[name="macchinario"]').val());
		
					var t_mac =  $(lav).find('input[name="time_lav"]').val();
					if(t_mac == ""){
						lavorazione.time =  time_to_sec( $(lav).find('.badge').text() );
					}
					else{
						lavorazione.time =  time_to_sec( $(lav).find('input[name="time_lav"]').val() );
					}
		
					lavorazione.n_pezzi =  Number($(lav).find('input[name="n_pezzi"]').val());
		
					lavorazione.n_scarti =  Number($(lav).find('input[name="n_scarti"]').val());
		
					params.lavorazioni.push(
						lavorazione
						);
				});
		
				$.ajax({
					type: "POST",
					url: document.location.origin + base_path + '/area_produzione/terminaLavorazione',
					data: { 
						params: params
					},
					success: function(result){
						lavorazione_block.addClass('locked');
						if(result == 'OK'){
							swal("Salvato", "tempo lavorazione salvato", "success");
						}
						else{
							swal("Errore", "tempo lavorazione NON salvato", "error");
						}
					},
					error: function(){
						swal("Errore", "tempo lavorazione NON salvato", "error");
					}
				});
		
	});
		
		
		

	$(document).on('click', '.btn_total_time_event', function() {

		var params = {};

		params.event = 1;

		if( $(this).data('timer') == 't_time_total'){ //se inizio tempo di lavorazione;
			$(this).removeClass('btn_total_time_event');
			params.event = 0;
		}

		var time_lav = $(document).find('#t_time_total').text().replace('sec','').replace('min','').trim();
		var sec_value = 0;

		if (time_lav == "00:00") {
			sec_value = 0;
		}
		else{
			sec_value = time_to_sec(time_lav);
		}

		params.sec_value = sec_value;


		$.ajax({
			type: "POST",
			url: document.location.origin + base_path + '/area_produzione/salvaTempOperatore',
			data: { 
				params: params
			},
			success: function(result){
				$(document).find('.tempo_totale').addClass('locked');
				if(result == 'OK'){
					swal("OK", "", "success");
				}
				else{
					swal("Errore", "ricaricare la pagina", "error");
				}
				
			},
			error: function(){
				swal("Errore", "ricaricare la pagina", "error");
			}
		});

		

	});

	function time_to_sec(time_lav){

		var time_sec  = 0;

		time_lav.replace('sec','').replace('min','').trim().split(':').reverse().forEach(function(el, index) {
			var value = Number(el);
			time_sec += (value * Math.pow(60, index));
		});
		return time_sec;
	}



});


