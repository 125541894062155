/*
* Comarp v30 - main js file
* */
	var url = "/app/Libraries/ajax";

	// var base_path = '/comarp/public';
	 var base_path = '/';


var comarp = {};

// Set locale globally
moment.locale('it');

comarp.highlightMenu = function(url){

	var menu = null;
	$('.comarp_topbar a').each(function(){
		if($(this).attr('href').trim() == url){
			menu = $(this);
		}
	});

	if(menu){
		menu.parent().addClass('active');
	}
};

jQuery(document).ready(function($){

	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		}
	});

	$('.custom_datepicker').datetimepicker({
        locale: 'it',
        format: 'DD/MM/YYYY'
    });

	// Modal elimina
	$(document).on("click",'a[data-type="elimina"]',function(evt) {

        evt.preventDefault();
        evt.stopPropagation();

        var url = $(this).attr('href');
        var modello = $(this).data( "element-model" );
        var id = $(this).data( "id" );

        if(!url || url.length == 0 || url == '#'){
            url = modello+'/'+id;
        }

		swal({  
			title: "Sei sicuro di voler eliminare?",    
			type: "warning",   
			showCancelButton: true,   
			confirmButtonColor: "#DD6B55",   
			confirmButtonText: "Si, elimina",   
			cancelButtonText: "No, non eliminare",   
			closeOnConfirm: false,   
			closeOnCancel: true 
		}).then(function () {   
		  
				//swal("Deleted!", "Your imaginary file has been deleted.", "success");   
				$.ajax({
					url: url,
					type: 'DELETE',
					success: function(result) {
						// Do something with the result
						if(result == 'OK'){
							 window.location.href = window.location.href;
						}else{
							swal("Errore", result, "error");
						}
					   
					}
				});
		 
		});

	});

	$('a[data-type="dettagli"]').click(function() {

		var dettagli = $(this).data('value');

        var elemento = $("#dettagli-elemento");
        elemento.find('.modal-body').html(dettagli);
        elemento.modal();

	});

    // Abilitiamo tutti i tooltip
	$('[data-toggle="tooltip"]').tooltip();

    // Tutte le select sono potenziate con Select2
    $('select').not(".not_select2").select2({

	});
	
	$("form[name=stampaEtichetteForm]").submit(function (e) {
		if( jQuery("form[name=stampaEtichetteForm] input[name^=etichette]:checked").length == 0 ) {
			e.preventDefault();
			swal("Selezionare tramite la colonna 'etichetta' quale devono essere stampate");
		}
	});

});

