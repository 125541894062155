jQuery(document).ready(function($) {


	 var url = "/app/Libraries/ajax";

	 //var base_path = '/comarp/public';
	 var base_path = '';
	
	$('input[name="disegno"]').parent().append('<div class="preview_image new_file" ></div>');

	

	$('.codice_articolo.typeahead').on('change', function() {
		
		var id_articolo = $(this).val().trim();
		
				// console.log(document.location.origin + base_path + '/articolo/search');
				$('.preview_image.new_f').remove();
		
				$.ajax({
					type: "POST",
					url: document.location.origin + base_path + '/articolo/searchStorico',
					data: { id_articolo: id_articolo },
					success: function(result){
				
						//  debugger;
						$('trix-editor[name="descrizione"]').val(result.descrizione);
						$('input[name="field_disegno_file_precomp"]').val(result.disegno_file);
						$('input[name="disegno"]').val(result.disegno);
						$('input[name="materiale"]').val(result.materiale);
						$('input[name="quantita"]').val(result.quantita);
						$('input[name="prezzoFinale"]').val(result.prezzoFinale);
				
		
						//creo link del nuovo file disegno
						if(result.disegno != ''){
		
						
						//	$('input[name="disegno"]').parent().append('<div class="preview_image new_file" ></div>');
		
							var linkname = 'disegno';
							var filename = result.disegno_file;
							$('.preview_image.new_f').remove();
							

							$.ajax({
								type: "POST",
								url: document.location.origin + base_path + '/articolo/getFileLink',
								data: { 
									filename: filename,
									linkname: linkname,
								},
								success: function(result2){
									if((result2 != "") && ($('.new_f').length == 0 )){
										$('.preview_image').insertAfter('.codice_articolo');
										$( result2 ).insertAfter( ".preview_image" );
									}
									
								}
							});
		
						}
						else{ // se non esiste un disegno
							$('.preview_image.new_file').remove();
						}
						
					},
					error: function(){
					}
				});
		
				// $('input[name="disegno_file"]').on('click', function(event) {
				// 	event.preventDefault();
				// 	$('.preview_image.new_file').remove();
				// 	$('input[name="field_disegno_file_precomp"]').val('');
				// });

	});

	$('select[name="cliente"]').on('change', function() {

		var cliente = $(this).val().trim();

		$.ajax({
			type: "POST",
			url: document.location.origin + base_path + '/account/search',
			data: { cliente: cliente },
			success: function(result){
				$('input[name="consegna"]').val(result[0].consegna);
				$('input[name="pagamento"]').val(result[0].pagamento);
				$('input[name="spedizione"]').val(result[0].spedizione);
			},
			error: function(){
			}
		});

	});


	$(document).on("click",'a.avvisa_cliente',function(evt) {

        evt.preventDefault();
		evt.stopPropagation();

		var offerta = $(this).parents('tr');
		var id_offerta = $(this).data('id');
		id_offerta = parseInt(id_offerta);

		var avvisato = offerta.find('.avviso span').text().trim().toLowerCase();

		if(avvisato == 'no'){
			swal({
				title: "Confermi di forzare tutte le righe pronte?",
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#DD6B55",
				confirmButtonText: "Si",
				cancelButtonText: "No",
				closeOnConfirm: false,
				closeOnCancel: true
			}).then(function () {

				$.ajax({
					type: "POST",
					url: document.location.origin + base_path + '/rdo/avvisa',
					data: { id_offerta: id_offerta },
					success: function (result) {

						if (result < 0) {
							sweetAlert("Errore", "Offerta non trovata", "error");
						}
						else if (parseInt(result)) {
							offerta.find('.avviso').html('<h3><span class="label label-success">Si</span></h3>');
						}
						else {
							offerta.find('.avviso').html('<h3 ><span class="label label-danger">No</span></h3>');
						}

					},
					error: function () {
					}
				});

			});
		}
		
		

		
		

	});


});