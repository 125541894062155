
$(function() {

    var table = $('#dataTable');
    var source = table.data('source');

    var table_header_fixed = $('.dataTable-header-fixed');

    var columns = [];
    $('thead th', table).each(function(){
        var key = $(this).data('column-key');
        var obj = {};
        obj.data = key;
        obj.name = $(this).data('column-name') || key;

        if (key == 'action') { //se è la colonna modifica
            obj['name'] = 'action';
            obj['orderable'] = false;
            obj['searchable'] = false;
        }

        // Custom renderer
        var type = $(this).data("type");
        if(type == 'date'){
           obj.render = function(data, type, row, meta ){
               if(!data) return "";
               if(data.length == 0) return "";
               return moment(data).format('DD/MM/YYYY');
           }
        }

        if(type == 'datetime'){
            obj.render = function(data, type, row, meta ){
                return moment(data).format('DD/MM/YYYY, HH:mm:ss');
            }
        }


        if (obj.name == 'lav_open') {
            obj.render = function (data, type, row, meta) {
                if (!data) return '<div class="avviso"><h3><span class="label label-success"> Si </span></h3></div>';
                else return '<div class="avviso"><h3 ><span class="label label-danger"> No </span></h3></div>';
            }
        }
        else if (type == 'boolean') {
            obj.render = function (data, type, row, meta) {
                if (data) return '<span class="label label-success"> Si </span>';
                else return '<span class="label label-danger"> No </span>';
            }
        }

        // Visibilità
        var hide = $(this).data('hide');
        if(hide){
            obj.visible = false;
        }
        columns.push(obj);
    });

    var options = {
        processing: true,
        serverSide: true,
        fixedHeader: true,
        ajax: source + '/anyData',
        columns: columns,
        dom: 'ipBtlfr',
        buttons: [
            {extend:'colvis', text: 'Mostra / Nascondi colonne'},
            'copy', 'csv', 'excel', 'pdf', 'print'
        ],
        language: {
            url: 'libs/datatable_italian.json'
        }
    };

    // Leggo l'ordine dalle colonne (è opzionale)
    var order = [[0, 'asc']];
    var i = 0;
    $('thead th', table).each(function(){
        var column_order = $(this).data('order');
        if(column_order && column_order.length > 0){
            order = [[i, column_order]];
        }
        i++;
    });
    options.order = order;

    // Preparo i campi di ricerca nel footer se è presente un tfoot
    i = 0;
    $('tfoot th', table).each( function () {
        var title = $(this).text();
        if(title.length == 0) return;
        $(this).html( '<input type="text" data-index=' + i + ' placeholder="Filtra ' + title + '" />' );
        i++;
    } );

    var dataTable = table.DataTable(options)
        .on( 'draw.dt', function () {
            // Abilitiamo i tooltip sui bottoni all'interno del datatable
            $('[data-toggle="tooltip"]').tooltip();
        });

    table_header_fixed.DataTable( {
            "scrollY":        "200px",
            "scrollCollapse": true,
            "paging":         true,
            "order": [[ 1, "desc" ]],
            "language": {
                "info": "Pagina _PAGE_ di _PAGES_",
                "infoEmpty": "Nessun valore"
            },
            "pagingType": "simple"
     } );

    // Filtri per colonna
    $('tfoot th input', table).on( 'keyup change', function () {
        var column = dataTable.columns($(this).data('index'));
        if ( column.search() !== this.value ) {
            column
                .search( this.value )
                .draw();
        }
    });

});

